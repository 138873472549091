"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var init_searchBox_and_hamburgerMenu = function init_searchBox_and_hamburgerMenu() {
  var body = document.getElementById('body');
  var fadeContent = document.getElementById('content-fade');
  var btnSearch = document.getElementById('search-button');
  var btnMobile = document.getElementById('hamburger');
  var menuSearch = document.getElementById('search-box');
  var menuMobile = document.getElementById('mobile-menu');
  var elementHide = function elementHide(btn, menu) {
    if (!menu.classList.contains('hidden')) {
      menu.classList.add('hidden');
      var _iterator = _createForOfIteratorHelper(btn.children),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var child = _step.value;
          child.classList.toggle('hidden');
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      ;
    }
  };
  var elementToggle = function elementToggle(btn, menu) {
    menu.classList.toggle('hidden');
    var _iterator2 = _createForOfIteratorHelper(btn.children),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var child = _step2.value;
        child.classList.toggle('hidden');
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    ;
    if (menu.getAttribute('id') === 'search-box') {
      elementHide(btnMobile, menuMobile);
    } else {
      elementHide(btnSearch, menuSearch);
    }
    if (menuSearch.classList.contains('hidden') && menuMobile.classList.contains('hidden')) {
      fadeContent.classList.add('hidden');
    } else {
      fadeContent.classList.remove('hidden');
    }
  };
  var isTargetConnectedToHeader = function isTargetConnectedToHeader(event) {
    var target = event.target;
    while (target !== null) {
      if (target.tagName === 'HEADER') {
        return true;
      }
      target = target.parentElement;
    }
    return false;
  };
  var clickOutside = function clickOutside(event) {
    var target = event.target.className;
    if (target.includes('o-header__container') || target.includes('content-fade') || !isTargetConnectedToHeader(event)) {
      fadeContent.classList.add('hidden');
      elementHide(btnMobile, menuMobile);
      elementHide(btnSearch, menuSearch);
    }
  };
  if (btnMobile && menuMobile) btnMobile.addEventListener("click", function () {
    return elementToggle(btnMobile, menuMobile);
  });
  if (btnSearch && menuSearch) btnSearch.addEventListener("click", function () {
    elementToggle(btnSearch, menuSearch);
    document.getElementsByClassName('search-box__input')[0].focus();
  });
  if (body) body.addEventListener("click", function () {
    return clickOutside(event);
  });
};
init_searchBox_and_hamburgerMenu();