"use strict";

var _scrollIntoView = _interopRequireDefault(require("scroll-into-view"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var parts = document.querySelector('*[data-portal-region=main]').querySelectorAll('*[data-portal-component-type=part]');
document.querySelector('.menu-anchor').addEventListener('click', function (e) {
  if (e.target !== e.currentTarget) {
    (0, _scrollIntoView.default)(parts[e.target.getAttribute('data-scroll-to')], {
      time: 800,
      align: {
        top: 0,
        topOffset: 11
      }
    });
  }
  e.stopPropagation();
});