"use strict";

var vacancyModal = function vacancyModal(modalContainer) {
  var body = document.getElementsByTagName("body")[0];
  var header = document.getElementsByTagName("header")[0];
  var footer = document.getElementsByTagName("footer")[0];
  var breadcrumbs = document.querySelector(".o-breadcrumbs");
  var mainContainer = document.querySelector(".main-container");
  var articleHeadingSelector = document.querySelector(".t-heading--article");
  var articleHeading = articleHeadingSelector ? articleHeadingSelector.innerText : "Stilling";
  var modalTitle = document.querySelector(".vacancy-title");
  var modalBtnOpen = document.querySelector(".vacancy-btn--modal");
  var modalBtnSubmit = document.querySelector(".vacancy-btn--form");
  var modalBtnClose = document.querySelector(".vacancy-modal__close");
  var modalForm = document.querySelector(".vacancy-modal__form");
  var modalThankYou = document.querySelector(".vacancy-modal__thank-you");
  header.before(modalContainer);
  modalTitle.innerText = articleHeading;
  var addOrRemoveClass = function addOrRemoveClass(htmlNodes, className, enabled) {
    if (!Array.isArray(htmlNodes) || !className) return false;
    for (var i = 0; i < htmlNodes.length; i++) {
      if (enabled === true && htmlNodes[i]) {
        htmlNodes[i].classList.add(className);
      }
      if (enabled === false && htmlNodes[i]) {
        htmlNodes[i].classList.remove(className);
      }
    }
  };
  var fadeBackground = function fadeBackground(enabled) {
    var classFadeBrightness = 'vacancy-modal__fade-bg-brightness';
    var classFadeBackground = 'vacancy-modal__fade-bg-color';
    if (enabled === true) {
      body.classList.add(classFadeBackground);
      addOrRemoveClass([header, footer, breadcrumbs, mainContainer], classFadeBrightness, enabled);
    } else {
      body.classList.remove(classFadeBackground);
      addOrRemoveClass([header, footer, breadcrumbs, mainContainer], classFadeBrightness, enabled);
    }
  };
  var modalOpen = function modalOpen() {
    fadeBackground(true);
    modalContainer.style.top = window.scrollY + "px";
    modalContainer.style.display = "flex";
    modalBtnClose.focus();
  };
  var modalClose = function modalClose() {
    fadeBackground(false);
    modalContainer.style.display = "none";
    modalBtnOpen.focus();
  };
  var isConnectedToModal = function isConnectedToModal(element) {
    if (!element) return false;
    var currentNode = element;
    while (currentNode !== null) {
      if (currentNode.classList.contains('vacancy-modal')) return true;
      currentNode = currentNode.parentElement;
    }
    return false;
  };
  var clickOutside = function clickOutside(event) {
    if (modalContainer.style.display === 'flex' && event.target.className !== modalBtnOpen.className) {
      if (!isConnectedToModal(event.target) || event.target.className === modalContainer.className || event.target.className === modalBtnClose.className) {
        modalClose();
      }
    }
  };
  var responseMessage = function responseMessage(text) {
    modalForm.style.display = "none";
    modalThankYou.style.display = "inline-block";
    modalThankYou.innerText = text.trim();
  };
  var registerApplicant = function registerApplicant(event) {
    if (modalForm.checkValidity()) {
      event.preventDefault();
      var formValues = {};
      var formElements = Array.from(modalForm.children);
      formElements.filter(function (node) {
        return node.nodeName === "INPUT";
      }).map(function (input) {
        if (input.name === "name") formValues.name = input.value;
        if (input.name === "phone") formValues.phone = input.value;
        if (input.name === "email") formValues.email = input.value;
        if (input.name === "linkedin") formValues.linkedin = input.value;
      });
      fetch('/_/service/no.tine.tine/webcruiter?' + new URLSearchParams({
        title: articleHeading,
        name: formValues.name,
        phone: formValues.phone,
        email: formValues.email,
        linkedin: formValues.linkedin
      })).then(function (response) {
        if (response.status !== 200) {
          responseMessage("\n\t\t\t\t\t\t\tDet har oppst\xE5tt en feil.\n\t\t\t\t\t\t\tVennligst kontakt sentralbordet p\xE5 +47 75 66 30 80.\n\t\t\t\t\t\t");
        }
      }).catch(function () {
        responseMessage("\n\t\t\t\t\t\tDet har oppst\xE5tt en feil.\n\t\t\t\t\t\tVennligst kontakt sentralbordet p\xE5 +47 75 66 30 80.\n\t\t\t\t\t");
      });
      responseMessage("\n\t\t\t\tTakk for din interesse.\n\t\t\t\tDu vil bli kontaktet om du er aktuell for stillingen.\n\t\t\t");
    }
  };
  modalBtnOpen.addEventListener('touchend', function () {
    return modalOpen();
  });
  modalBtnOpen.addEventListener('mouseup', function () {
    return modalOpen();
  });
  body.addEventListener('touchend', function (e) {
    return clickOutside(e);
  });
  body.addEventListener('mouseup', function (e) {
    return clickOutside(e);
  });
  modalBtnSubmit.addEventListener('touchend', function (e) {
    return registerApplicant(e);
  });
  modalBtnSubmit.addEventListener('mouseup', function (e) {
    return registerApplicant(e);
  });
  document.addEventListener("keyup", function (e) {
    if (e.key === "Enter" && e.target.className.includes(modalBtnOpen.className)) {
      modalOpen();
    }
    if (e.key === "Escape" || e.key === "Enter" && e.target.className === modalBtnClose.className) {
      modalClose();
    }
    if (e.key === "Tab" && modalContainer.style.display === 'flex') {
      if (e.shiftKey) {
        if (isConnectedToModal(document.activeElement) === false) {
          modalBtnSubmit.focus();
        }
      } else {
        if (isConnectedToModal(document.activeElement) === false) {
          modalBtnClose.focus();
        }
      }
    }
  });
};
var VACANCY_MODAL_CONTAINER = document.querySelector(".vacancy-modal") || null;
if (VACANCY_MODAL_CONTAINER) {
  vacancyModal(VACANCY_MODAL_CONTAINER);
}