/* global google */
export default class PlantMap {
	constructor(mapMarkers) {
		const map = new google.maps.Map(document.getElementById('map'), this.getMapOptions());
		const bounds = new google.maps.LatLngBounds();
		const zoomLevel = (mapMarkers.length > 1) ? 5 : 10;

		mapMarkers.forEach((marker) => {
			const pos = new google.maps.LatLng(marker.latitude, marker.longtitude);

			bounds.extend(pos);

			const mapMarker = new google.maps.Marker({
				map,
				position: pos,
				title: marker.title,
				url:	marker.url
			});

			map.fitBounds(bounds);

			google.maps.event.addListener(mapMarker, 'click', () => {
				window.location.href = marker.url;
			});
		});


		const zoomChangedListener = google.maps.event.addListener((map), 'zoom_changed', () => {
			const boundsListener = google.maps.event.addListener((map), 'bounds_changed', () => {
				map.setZoom(zoomLevel);
				google.maps.event.removeListener(boundsListener);
			});
			google.maps.event.removeListener(zoomChangedListener);
		});
	}

	getMapOptions() {
		return {
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			navigationControl: false,
			mapTypeControl: false,
			scaleControl: false,
			scrollwheel: false,
			draggable: true,
			disableDefaultUI: false,
			disableDoubleClickZoom: true,
			styles: this.getStyles()
		};
	}

	getStyles() {
		return [{
			featureType: 'water',
			stylers: [{
				color: '#cfe7f3'
			}]
		},
		{
			featureType: 'road',
			elementType: 'geometry.fill',
			stylers: [{
				color: '#e9e0dc',
				visibility: 'simplified'
			}]
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [{
				color: '#ffffff'
			}]
		},
		{
			featureType: 'landscape.natural.terrain'
		},
		{
			featureType: 'road',
			elementType: 'geometry.stroke',
			stylers: [{
				color: '#ffffff'
			}]
		}, {
			featureType: 'poi',
			elementType: 'labels',
			stylers: [{
				visibility: 'off'
			}]
		}, {
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [{
				color: '#e2e6e2'
			}]
		}, {
			featureType: 'road',
			elementType: 'labels',
			stylers: [{
				visibility: 'off'
			}]
		}];
	}
}
